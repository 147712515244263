import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MustMatch } from '@core/forms/validators/must-match.validator';
import { UserConfirm } from '../../../../model/user-confirm.model';
import { ConfirmService } from '../../../../services/confirm/confirm.service';

@Component({
    selector: 'account-confirm-form',
    templateUrl: './account-confirm-form.component.html',
    styleUrls: ['./account-confirm-form.component.scss']
})
export class AccountConfirmFormComponent implements OnInit {

    @Input() dataAccount:any;
    @Input() accountHash:string;


    public form: FormGroup;
    protected password: any = {invalid: true};

    // public patternMayus:any;
    // public patternSpecial:any;
    // public patternNumber:any;
    // public minLength:any;


    public loading:boolean = false;
    public response:Object = null;

    public statusCode:number;


    constructor(private formBuilder: FormBuilder, public confirmSrv : ConfirmService) { }

    ngOnInit() {
        this.setFormGroup();
    }

    setFormGroup(){
        this.form = this.formBuilder.group({
            password: ['', [Validators.required]],
            passwordConfirm: ['', Validators.required],
            userName: [''],
            orgName: [''],
            accountEmail: ['']
        }, {
            validator: MustMatch('password', 'passwordConfirm')
        });
        // this.form.get('password').valueChanges.subscribe(value => {
        //     // console.debug("value -->", value);
        //     // this.passwordValid = this.passwordValidator.validate(value);
        //     // console.debug("valid-->", this.passwordValid);
        //
        //     // this.patternMayus = values.match(/(?=.*[A-Z])/g);
        //     // this.patternNumber = values.match(/(?=.*[0-9])/g);
        //     // this.patternSpecial = values.match(/(?=.*[.$@$!%#*?&])/g);
        //     // this.minLength = values.match(/(\w){5,}/g);
        // });
    }

    onPasswordValid(valid: boolean) {
        console.debug("valid--->", valid);
        this.password.invalid = !valid;
    }

    invalid() {
        return this.form.invalid || this.password.invalid;
    }

    onSubmit() {
        if(this.invalid())
            return;

        var data: Object = {
            user_name: this.form.value.userName,
            organization_name: this.form.value.orgName,
            password: this.form.value.password,
            password_copy: this.form.value.passwordConfirm
        };

        this.accountConfirm(data);
    }


    accountConfirm(data) {
        console.log("confirm account ",data)
        this.loading = true;
        this.confirmSrv.setAccountConfirm(data, this.accountHash).subscribe(
            data => {
                console.log(data);
                this.loading = false;
                this.statusCode = 200;
                this.response = {
                    title: "<strong>Great!</strong><br/>Your invitation is confirmed",
                    description: "You can now <a href='/login'><strong>login</strong>.</a>", //RouterLink not work in innerhtml
                    typeMessage: 'success',
                };
            },
            error => {
                console.log(error);
                this.loading = false;
                this.controlError(error);
            }
        )
    }

    controlError(error) {
        switch (error.status_code) {
            case 422:
                this.statusCode = 422;
                break;
            default:
                this.statusCode = error.status;
                this.response = {
                    description: error.statusText
                };
                break;
        }
    }
}
