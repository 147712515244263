import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import { HttpClientModule } from '@angular/common/http';
//import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DeviceDetectorModule } from 'ngx-device-detector';

// Imports
import { CoreModule } from '@core/core.module';
import { LoginMainComponent } from './components/layout/main/main.component';

// bootstrap
import { LoginComponents } from './components/login.components';

@NgModule({
    imports: [
        BrowserModule,
        CoreModule,
        LoginComponents,
        FormsModule,
        ReactiveFormsModule,
        DeviceDetectorModule.forRoot()
    ],
    providers: [],
    bootstrap: [
        LoginMainComponent
    ]
})
export class AppModule { }
