import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { LoginRouterModule } from '@login/router.module';
import { LoginMainComponent } from '@login/components/layout/main/main.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        LoginRouterModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LoginMainComponent,
    ],
    exports: [
        LoginMainComponent,
        LoginRouterModule
    ],

})
export class LayoutModule { }
