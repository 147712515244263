import { Component } from '@angular/core';

@Component({
    selector: 'login-ie11-alert',
    templateUrl: './ie11-alert.component.html',
    styleUrls: ['./ie11-alert.component.scss']
})
export class LoginIe11AlertComponent {
    constructor() { }
}
