import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MustMatch } from '@core/forms/validators/must-match.validator';
import { UserConfirm } from '../../../../model/user-confirm.model';
import { ConfirmService } from '../../../../services/confirm/confirm.service';

@Component({
    selector: 'user-confirm-form',
    templateUrl: './user-confirm-form.component.html',
    styleUrls: ['./user-confirm-form.component.scss']
})
export class UserConfirmFormComponent implements OnInit {

    @Input() dataUser:UserConfirm;
    @Input() userHash:string;

    public form: FormGroup;

    protected password: any = {invalid: true};

    // Control form
    // public patternMayus:any;
    // public patternSpecial:any;
    // public patternNumber:any;
    // public minLength:any;


    public loading:boolean = false;
    public response:Object = null;

    public statusCode:number;


    constructor(private formBuilder: FormBuilder, public confirmSrv : ConfirmService) { }

    ngOnInit() {
        this.setFormGroup();
    }

    setFormGroup(){
        if(!this.dataUser.user_exists) {
            this.form = this.formBuilder.group({
                password: ['', [Validators.required]],
                passwordConfirm: ['', Validators.required],
                username: [''],
                useremail: ['']
            }, {
                validator: MustMatch('password', 'passwordConfirm')
            });
            // this.form.get('password').valueChanges.subscribe(values => {
            //   this.patternMayus = values.match(/(?=.*[A-Z])/g);
            //   this.patternNumber = values.match(/(?=.*[0-9])/g);
            //   this.patternSpecial = values.match(/(?=.*[$@$!%#*?&])/g);
            //   this.minLength = values.match(/(\w){5,}/g);
            // });
        }

        if (this.dataUser.user_exists){
            this.form = this.formBuilder.group({
                username: [''],
                useremail: ['']
            });
        }
    }

    onPasswordValid(valid: boolean) {
        console.debug("valid--->", valid);
        this.password.invalid = !valid;
    }

    invalid() {
        return this.form.invalid || this.password.invalid;
    }

    onSubmit() {
        if(!this.dataUser.user_exists && this.invalid())
            return;

        var data: Object;
        if(!this.dataUser.user_exists) {
            data = {
                email: this.dataUser.email,
                password: this.form.value.password,
                password_copy: this.form.value.passwordConfirm
            }
        }

        if (this.dataUser.user_exists) {
            data = {
                email: this.dataUser.email
            }
        }
        this.confirmUser(data);
    }


    confirmUser(data) {
        console.log("confirm user ",data)
        this.loading = true;
        this.confirmSrv.setUserConfirm(data, this.userHash).subscribe(
            data => {
                console.log(data);
                this.loading = false;
                this.statusCode = 200;
                this.response = {
                    title: "<strong>Great!</strong><br/>Your invitation is confirmed",
                    description: "You can now <a href='/login'><strong>login</strong>.</a>", //RouterLink not work in innerhtml
                    typeMessage: 'success',
                };
            },
            error => {
                console.log(error);
                this.loading = false;
                this.controlError(error);
            }
        )
    }

    controlError(error) {
        switch (error.status_code) {
            case 422:
                this.statusCode = 422;
                break;
            default:
                this.statusCode = error.status;
                this.response = {
                    description: error.statusText
                };
                break;
        }
    }
}
