import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { MustMatch } from '@core/forms/validators/must-match.validator';
import { Router, ActivatedRoute } from '@angular/router';

import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'login-app-recovery',
    templateUrl: './recovery.component.html',
    styleUrls: ['../../../../styles-login/styles-login.scss','./recovery.component.scss']
})
export class LoginRecoveryComponent implements OnInit{
    public form: FormGroup;

    // private password:string;
    // private passwordConfirm:string;
    public error:boolean;
    public errorRequest:boolean;
    public recovery:boolean = false;
    public loading:boolean = true;
    public used:boolean = false;
    private url:string = "";

    public password: any = {invalid: true};

    public patternMayus:any;
    public patternSpecial:any;
    public patternNumber:any;
    public minLength:any;
    //public hash:string;
    constructor(private route: ActivatedRoute, public httpClient: HttpClient, private formBuilder: FormBuilder, private urlSrv: UrlService ) {}

    ngOnInit() {
        this.url = this.urlSrv.build("/login/recovery/" + this.route.params['value'].hash);
        this.getStatusRecovery();
        this.form = this.formBuilder.group({
            password: ['', [Validators.required]],
            passwordConfirm: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'passwordConfirm')
        });
        // this.form.get('password').valueChanges.subscribe(values => {
        //   this.patternMayus = values.match(/(?=.*[A-Z])/g);
        //   this.patternNumber = values.match(/(?=.*[0-9])/g);
        //   this.patternSpecial = values.match(/(?=.*[$@$!%#*?&])/g);
        //   this.minLength = values.match(/(\w){5,}/g);
        // });
    }

    onPasswordValid(valid: boolean) {
        console.debug("valid--->", valid);
        this.password.invalid = !valid;
    }

    invalid() {
        return this.form.invalid || this.password.invalid;
    }

    getStatusRecovery(){
        this.httpClient.get<string>(this.url)
        .subscribe(
            (data:any) => {
                console.log('request success: '+JSON.stringify(data));
                this.loading = false;
                this.recovery = false;
            },
            (error:any) => {
                console.log('Error request: '+JSON.stringify(error));
                this.recovery = true;
                this.loading = false;
                if(error.status == 403){
                    this.errorRequest = true;
                }else{
                    this.errorRequest = false;
                    this.error = true;
                }
            }
        );

    }

    onSubmit(){
        if(this.invalid())
            return;

        this.error = false;
        this._setPassword(this.form.value.password, this.form.value.passwordConfirm)
        .subscribe(
            (data:any) => {
                console.log('recovery success: '+data);
                this.recovery = true;
                this.used = true;
            },
            (error:any) => {
                console.log('Error recovery: '+JSON.stringify(error));
                this.error = true;
            }
        );
    }

    private _setPassword(password:string, passwordConfirm:string) {
        let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        return this.httpClient.post<string>(this.url, { password: password, password_copy: passwordConfirm}, options);
    }

}
