import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPasswordComponent } from '@login/components/app/password/password.component';
import { LoginMainComponent } from '@login/components/app/main/main.component';

import { LoginRecoveryComponent } from '@login/components/app/recovery/recovery/recovery.component';
import { LoginRecoveryRequestComponent } from '@login/components/app/recovery/recovery-request/recovery-request.component';
import { UserConfirmMainComponent } from './components/app/user-confirm/user-confirm-main/user-confirm-main.component';
import { AccountConfirmMainComponent } from './components/app/account-confirm/account-confirm-main/account-confirm-main.component';
import { AutoKeyComponent } from './components/app/auto-key/auto-key.component';
import { SessionsMainComponent } from './components/app/sessions/main/main.component';


const routes: Routes = [
    { path: '', component: LoginMainComponent },
    { path: 'into', component: LoginPasswordComponent},
    { path: 'into/:orgId', component: LoginPasswordComponent},
    { path: 'recovery', component: LoginRecoveryRequestComponent },
    { path: 'recovery/:type', component: LoginRecoveryRequestComponent },
    { path: 'recovery/credentials/:hash', component: LoginRecoveryComponent },
    { path: 'confirm/:hash', component: UserConfirmMainComponent },
    { path: 'account/confirm/:hash', component: AccountConfirmMainComponent },
    { path: 'auto-key/:hash/:checksum', component: AutoKeyComponent },
    { path: 'sessions', component: SessionsMainComponent },
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class LoginRouterModule { }
