import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ConfirmService {

	private readonly URL = "/web/login/confirm/";
	private readonly URL_ACCOUNT = "/web/login/account/confirm/";
	private readonly URL_WINDOW = window.location.href;

    constructor(public http: HttpClient) {}

	public getHashUrl() {
		return this.URL_WINDOW.substring(this.URL_WINDOW.lastIndexOf('/') + 1);
	}

	public getUserConfirm(userHash) {
		return this._get(this.URL+userHash);
	}

	public setUserConfirm(data, userHash) {
		return this._post(this.URL+userHash, data);
	}

	public getAccountConfirm(accountHash) {
		return this._get(this.URL_ACCOUNT+accountHash);
	}

	public setAccountConfirm(data, accountHash){
		return this._post(this.URL_ACCOUNT+accountHash, data);
	}

	private _get(url:string): any {
		return this.http.get<string>(url);
	}

	private _post(url:string, data:string) {
		let options = {headers: new HttpHeaders({
			'Content-Type':  'application/json',
		})};
		return this.http.post<string>(url, data, options);
	}
}
