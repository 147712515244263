import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

//import { UrlService } from '@core/services/url/url.service';

import { LoginService } from '../../../services/login/login.service';

@Component({
    selector: 'login-app-main',
    templateUrl: './main.component.html',
    styleUrls: ['../../../styles-login/styles-login.scss','./main.component.scss']
})
export class LoginMainComponent implements OnInit{

    public form: FormGroup;

    public validBrowser: boolean = true;
    public error: boolean = false;

    public textError:string;
    public next: boolean = false;
    public done: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private login: LoginService) { }

    ngOnInit() {
        //this.org = this.route.snapshot.paramMap.get("orgId");

        //this.returnUrl = this.route.snapshot.queryParams['href'] || '/app';
        //console.log(this.returnUrl);
        this.validBrowser = this.login.isValidBrowser();

        this.error = false;
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
        this.form.valueChanges.subscribe(value => {
            this.error = false;
        });
    }

    onReset() {
        this.next = false;
        this.done = false;
    }

    onAutoKey(){
        if(this.form.invalid)
            return;

        this.error = false;
        this._autokey(this.form.value.email).subscribe(
            (data:any) => {
                // console.log(this.returnUrl);
                // window.location.href = this.returnUrl;
                this.done=true;
            },
            (error:any) => {
                console.log('Error autokey: '+JSON.stringify(error));
                // this.error = true;
                this.controlErrors(error);
            }
        );
    }

    onNext(){
        this.next = true;
        //this.router.navigate(['/into']);
    }

    private _autokey(email: string) {
        // let options = { headers: new HttpHeaders({
        //     'Content-Type':  'application/json',
        // })};
        // let url = this.url.build("/login/auto-key");
        //
        // return this.httpClient.post<string>(url, { email:email }, options);

        return this.login.autokey({email: email});
    }

    private controlErrors(error){
        switch (error.status){
            // case 401:
            //     this.textError = "This user is no longer active in any accounts. Please contact <a href=\"mailto:support@twonas.com\" target=\"_blank\">support@twonas.com</a> so we can help you get your account back.";
            //     break;
            // case 403:
            //     this.textError = "The combination of e-mail and password are incorrect!";
            //     break;
            // case 419:
            //     this.textError = "Your password is expired. Please recover your password.";
            //     this.router.navigate(['/recovery/expired'], { queryParams: { e: this.form.value.email } })
            //     break;
            default:
                this.textError = "Sorry, an error has ocurred. Try again in a few minutes";
                break;
            }
        this.error = true;
    }
}
