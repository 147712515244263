import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CoreModule } from '@core/core.module';

import { UserConfirmFormComponent } from './user-confirm-form/user-confirm-form.component';
import { UserConfirmMainComponent } from './user-confirm-main/user-confirm-main.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        UserConfirmFormComponent,
        UserConfirmMainComponent,
    ],
    exports: [
        UserConfirmFormComponent,
        UserConfirmMainComponent,
    ],

})
export class UserConfirmModule { }
