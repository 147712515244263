import { Component, OnInit } from '@angular/core';
import { UserConfirm } from '../../../../model/user-confirm.model';
import { ConfirmService } from '../../../../services/confirm/confirm.service';


@Component({
    selector: 'account-confirm-main',
    templateUrl: './account-confirm-main.component.html',
    styleUrls: ['./account-confirm-main.component.scss']
})
export class AccountConfirmMainComponent implements OnInit {


    public loading :boolean = true;
    public error:Object = null;

    public accountHash :string = null;

    public dataAccount : any = null;

    
    constructor( public confirmSrv : ConfirmService) { }
    
    ngOnInit() {
        this.accountHash = this.confirmSrv.getHashUrl();
        this.getAccount();
    }

    getAccount(){
        this.confirmSrv.getAccountConfirm(this.accountHash).subscribe(
            data => {
                console.log('DataAccount----' , data),
                this.loading = false,
                this.dataAccount = data;
            },
            error => {
                console.log('error', error),
                this.loading = false,
                this.controlError(error);
            }
        );
    }

    controlError(error) {
        switch (error.status) {
            case 226:  
                this.error = {
                    title: 'This invitation has been used',
                    description: "You can recover your password <a href='/login/recovery'><strong>here</strong></a>",
                    typeMessage: 'error',
                    status: error.status
                }
                break;
            case 410:
                this.error = {
                    title: 'This invitation has expired:',
                    description: 'Please try requesting a new account or contact us via <strong>support@twonas.com<strong>',
                    typeMessage: 'error',
                    status: error.status
                }
                break;
            default:
                this.error = {
                    status: error.status,
                    message: error.statusText
                };
                break;
        };
    }
}
