// formulario para la petición del recovery
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { TitleService } from '@core/services/title.service';


@Component({
    selector: 'login-app-request-recovery',
    templateUrl: './recovery-request.component.html',
    styleUrls: ['../../../../styles-login/styles-login.scss', './recovery-request.component.scss']
})
export class LoginRecoveryRequestComponent implements OnInit{
    public email = new FormControl('', [Validators.required, Validators.email]);
    public recovery:boolean = false;
    public error:boolean = false;
    private url:string = '/web/login/recovery';

    public description: string;

    constructor(public httpClient: HttpClient, public route: ActivatedRoute, private title: TitleService) { }


    ngOnInit(){
        this.title.set("Recovery password");
        
        this.email.valueChanges.subscribe(value => {
            this.error = false;
        });

        let email = this.route.snapshot.queryParams['e'] || null;
        if(email != null)
            this.email.setValue(email);

        this.setDescription(this.route.snapshot.paramMap.get("type"));
    }

    onSubmit(){
        if(this.email.invalid)
            return;

        this._recoveryPassword(this.email.value)
        .subscribe(
            (data:any) => {
                console.log('request success: '+data);
                this.recovery = true;
                // window.location.href = '/login/request/success';
            },
            (error:any) => {
                console.log('Error request: '+JSON.stringify(error));
                this.error = true;
            }
        );
    }

    protected setDescription(type: string) {
        switch(type) {
            case 'expired':
                this.description = "Your password is expired. Please recover your password.";
                break;
        }
    }

    private _recoveryPassword(email: string) {
        let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        return this.httpClient.post<string>(this.url, { email:email}, options);
    }


}
