import { Component } from '@angular/core';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';
// import { BrowserService } from '@core/services/browser.service';

@Component({
    selector: 'login-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class LoginMainComponent {

    public deprecated: boolean = false;

    constructor(protected url : UrlService, protected title: TitleService) {
        this.url.setPrefix("/web");
        this.title.setPrefix("Login - Twona");
        // this.deprecated = !this.browser.isValidBrowser();
    }

}
