import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CoreModule } from '@core/core.module';

import { LoginRecoveryComponent } from './recovery/recovery.component';
import { LoginRecoveryRequestComponent } from './recovery-request/recovery-request.component';
// TODO include others components ....

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LoginRecoveryComponent,
        LoginRecoveryRequestComponent,
    ],
    exports: [
        LoginRecoveryComponent,
        LoginRecoveryRequestComponent,
    ],

})
export class LoginRecoveryModule { }
