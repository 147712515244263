import { Component, OnInit } from '@angular/core';
import { UserConfirm } from '../../../../model/user-confirm.model';
import { ConfirmService } from '../../../../services/confirm/confirm.service';


@Component({
    selector: 'user-confirm-main',
    templateUrl: './user-confirm-main.component.html',
    styleUrls: ['./user-confirm-main.component.scss']
})
export class UserConfirmMainComponent implements OnInit {


    public loading :boolean = true;
    public error:Object = null;

    public userHash :string = null;

    public dataUser : UserConfirm = null;

    
    constructor( public confirmSrv : ConfirmService) { }
    
    ngOnInit() {
        this.userHash = this.confirmSrv.getHashUrl();
        this.getUser();
    }

    getUser(){
        this.confirmSrv.getUserConfirm(this.userHash).subscribe(
            data => {
                console.log('Datauser----' , data),
                this.loading = false,
                this.dataUser = data;
            },
            error => {
                console.log('error', error),
                this.loading = false,
                this.controlError(error);
            }
        );
    }

    controlError(error) {
        switch (error.status) {
            case 226:
                this.error = {
                    title: 'This invitation has been used',
                    description: '',
                    typeMessage: 'error',
                    status: error.status
                }
                break;
            case 410:
                this.error = {
                    title: 'This invitation has expired:',
                    description: 'Please, contact with your administrator to request a new invitation',
                    typeMessage: 'error',
                    status: error.status
                }
                break;
            default:
                this.error = {
                    status: error.status,
                    message: error.statusText
                };
                break;
        };
    }
}
