import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';

import { CoreModule } from '@core/core.module';

import { LoginMainComponent } from './main/main.component';
import { LoginPasswordComponent } from './password/password.component';
import { AutoKeyComponent } from './auto-key/auto-key.component';
import { LoginIe11AlertComponent } from './ie11-alert/ie11-alert.component';

import { LoginRecoveryModule } from './recovery/recovery.module';
import { UserConfirmModule } from './user-confirm/user-confirm.module';
import { AccountConfirmModule } from './account-confirm/account-confirm.module';
import { SessionsModule } from './sessions/sessions.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        LoginRecoveryModule,
        UserConfirmModule,
        AccountConfirmModule,
        SessionsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LoginMainComponent,
        LoginPasswordComponent,
        AutoKeyComponent,
        LoginIe11AlertComponent
    ],
    exports: [
        LoginMainComponent,
        LoginPasswordComponent,
        UserConfirmModule,
        AccountConfirmModule,
        SessionsModule,
        AutoKeyComponent,
        LoginIe11AlertComponent
    ],

})
export class AppModule { }
