import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'app-sessions-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SessionsMainComponent implements OnInit{

    readonly URL = "/login/sessions";

    public sessions = [];
    public loading = true;
    private returnUrl: string;

	constructor(private http: HttpClient, private url: UrlService, private router:Router, private route: ActivatedRoute){}

	ngOnInit(){
        this.returnUrl = this.route.snapshot.queryParams['href'] || '/app';
        this._getSessions();
	}

    protected _getSessions() {
        let url = this.url.build(this.URL);

        this.http.get(url).subscribe(
            (data: any) => {
                this.sessions = data;
                //console.debug("SESSIONS>>", data);
                this.loading = false;
                this._checkSessions();
            },
            (error:any) => {
                // TODO
                this._goToLogin();
            }
        )
    }

    protected _checkSessions() {
        if(this.sessions.length == 0)
            this._goToLogin();

        if(this.sessions.length == 1) {
            if(this.sessions[0].current)
                this._goToApp();
            else
                this._goToLogin();
        }

    }

    protected _goToLogin() {
        this.router.navigate(['/']);
    }

    protected _goToApp() {
        window.location.href = this.returnUrl;
    }

    submit() {
        this.loading = true;
        let url = this.url.build(this.URL);

        this.http.delete(url).subscribe(
            (data: any) => {
                this._getSessions();
            },
            (error:any) => {
                this._goToLogin();
            }
        )
    }
}
