import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SessionsMainComponent } from './main/main.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        FormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SessionsMainComponent,
    ],
    exports: [
        SessionsMainComponent
    ],

})
export class SessionsModule { }
