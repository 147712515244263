import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventService } from '../event/event.service';

import { UrlService } from '@core/services/url/url.service';

import { DeviceDetectorService } from 'ngx-device-detector';
// import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    static ERROR_UNAUTHORIZED = 401;
    static ERROR_FORBIDDEN = 403;
    static ERROR_SESSION_EXISTS = 409;
    static ERROR_EXPIRED = 419;

    static readonly URL_AUTO_KEY = "/login/auto-key";
    static readonly URL_LOGIN = "/login";

    constructor(private http: HttpClient, private device: DeviceDetectorService, private url: UrlService) {}

    protected _merge(data: any) {
        // let local = DateTime.local();
        let device = {
            device: this.device.getDeviceInfo().userAgent,
            // timezone: local.zoneName
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        return {...device, ...data};
    }

    public login(data: any) {
        return this._send(data, LoginService.URL_LOGIN);
    }

    public autokey(data: any) {
        return this._send(data, LoginService.URL_AUTO_KEY);
    }

    // @deprecated (remove, please)
    public  isValidBrowser(): boolean {
        return this.device.getDeviceInfo().browser != "IE";
    }

    protected _send(data: any, url: string) {
        let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        url = this.url.build(url);

        data = this._merge(data);
        console.debug("LOGINNNNNN", data);
        return this.http.post<string>(url, data, options);
    }

}
