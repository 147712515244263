import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

//import { UrlService } from '@core/services/url/url.service';

import { LoginService } from '../../../services/login/login.service';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'login-app-password',
    templateUrl: './password.component.html',
    styleUrls: ['../../../styles-login/styles-login.scss','./password.component.scss']
})
export class LoginPasswordComponent implements OnInit{
    @Input() email: string = "";

    public form: FormGroup;

    private password:string;
    //private email:string;

    public error: boolean = false;
    public returnUrl: string;

    public org:string = null;

    public textError:string;
    //private url:string = '/web/login';

    constructor(private router:Router, private route: ActivatedRoute, private login: LoginService, private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.org = this.route.snapshot.paramMap.get("orgId");

        this.returnUrl = this.route.snapshot.queryParams['href'] || environment.appBasePath;
        //console.log(this.returnUrl);
        this.error = false;
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        this.form.patchValue({ email: this.email});
        this.form.valueChanges.subscribe(value => {
            this.error = false;
        });
    }

    onSubmit(){
        if(this.form.invalid)
            return;

        this.error = false;
        this._setLogin(this.form.value.email, this.form.value.password).subscribe(
            (data:any) => {
                console.log('login success: '+data);
                //console.log(this.returnUrl);
                // this.router.navigate([this.returnUrl]);
                window.location.href = this.returnUrl;
            },
            (error:any) => {
                console.log('Error login: '+JSON.stringify(error));
                // this.error = true;
                this.controlErrors(error);
            }
        );
    }

    private _setLogin(email:string, password:string) {

        // let options = { headers: new HttpHeaders({
        //     'Content-Type':  'application/json',
        // })};
        // let url = this.url.build("/login");
        // if (this.org != null) {
        //     return this.httpClient.post<string>(url, { email:email, password: password, organization: +this.org}, options);
        // }
        // return this.httpClient.post<string>(url, { email:email, password: password}, options);

        let data = { email:email, password: password};
        if (this.org != null) {
            data = {...data, ...{organization: +this.org}};
        }

        return this.login.login(data);
    }

    private controlErrors(error){
        switch (error.status){
            case LoginService.ERROR_UNAUTHORIZED:
                this.textError = "This user is no longer active in any accounts. Please contact <a href=\"mailto:support@twonas.com\" target=\"_blank\">support@twonas.com</a> so we can help you get your account back.";
                break;
            case LoginService.ERROR_FORBIDDEN:
                this.textError = "The combination of e-mail and password are incorrect!";
                break;
            // case LoginService.ERROR_SESSION_EXISTS:
            //     this.textError = "You already have a session started on another device.";
            //     console.debug(error);
            //     this.router.navigate(['/recovery/expired'], { queryParams: { e: this.form.value.email } })
            //     break;
            case LoginService.ERROR_EXPIRED:
                this.textError = "Your password is expired. Please recover your password.";
                this.router.navigate(['/recovery/expired'], { queryParams: { e: this.form.value.email } })
                break;
            default:
                this.textError = "Sorry, an error has ocurred. Try again in a few minutes";
                break;
            }
        this.error = true;
    }
}
