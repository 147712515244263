import { Component, OnInit } from '@angular/core';
//import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

//import { MustMatch } from '@core/forms/validators/must-match.validator';
import { Router, ActivatedRoute } from '@angular/router';

import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'login-app-auto-key',
    templateUrl: './auto-key.component.html',
    styleUrls: ['../../../styles-login/styles-login.scss','./auto-key.component.scss']
})
export class AutoKeyComponent implements OnInit {
    protected url : string;
    protected redirect: string;
    public error: number = 0;

    constructor(private route: ActivatedRoute, public httpClient: HttpClient, private urlSrv: UrlService ) {}

    ngOnInit() {
        this.url = this.urlSrv.build("/login/auto-key/" + this.route.params['value'].hash + "/" + this.route.params['value'].checksum);
        this.redirect = this.route.snapshot.queryParams['href'] || '/app';

        this.load();
    }

    protected load() {
        this.httpClient.get(this.url)
        .subscribe(
            (data:any) => {
                window.location.href = this.redirect;
            },
            (error:any) => {
                this.error = error.status;
            }
        );
    }

    // getStatusRecovery(){
    //     this.httpClient.get<string>(this.url)
    //     .subscribe(
    //         (data:any) => {
    //             console.log('request success: '+JSON.stringify(data));
    //             this.loading = false;
    //             this.recovery = false;
    //         },
    //         (error:any) => {
    //             console.log('Error request: '+JSON.stringify(error));
    //             this.recovery = true;
    //             this.loading = false;
    //             if(error.status == 403){
    //                 this.errorRequest = true;
    //             }else{
    //                 this.errorRequest = false;
    //                 this.error = true;
    //             }
    //         }
    //     );
    //
    // }


}
